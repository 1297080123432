<template>

<com-list class="news" api="news" :block="block" :tags="$constants.newsTags" v-on:loaded="onListLoaded">

	<template v-for="(item, index) in list">

		<a v-if="isExternal(item.url)" :href="item.url" target="_blank" class="list-item"  :key="index">

			<div class="list-item-head" :data-type="item.type">{{ $constants.newsTags[item.type] }}</div>

			<h2 class="list-item-name">{{ item.name }}</h2>
			<div class="list-item-date">{{ item.date | formatDate('MMMM D, YYYY') }}</div>
			<p class="list-item-text">{{ item.text }}</p>

		</a>

		<router-link v-if="!isExternal(item.url)" :to="$link($route.path + '/' + item.url)" class="list-item" :key="index">

			<div class="list-item-head" :data-type="item.type">{{ $constants.newsTags[item.type] }}</div>

			<h2 class="list-item-name">{{ item.name }}</h2>
			<div class="list-item-date">{{ item.date | formatDate('MMMM D, YYYY') }}</div>
			<p class="list-item-text">{{ item.text }}</p>

		</router-link>
	
	</template>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	},

	methods: {

		isExternal: function(url) {

			return url.indexOf('http') === 0

		}

	}

}

</script>

<style scoped>

.list-item {
	width: calc(33.3% - 14px);
	border: 1px solid #dbdbdb;
	border-radius: 13px;
	padding: 10px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.is-mobile .list-item {
	width: 100%;
	margin-right: 0px;
}

.list-item:nth-child(3n) {
	margin-right: 0px;
}

.list-item-head {
	width: 100%;
	height: 54px;
	line-height: 54px;
	color: #fff;
	text-align: right;
	font-size: 12px;
	letter-spacing: 1.2px;
	border-radius: 7px;
	background-color: #ccc;
	text-transform: uppercase;
	padding: 0px 20px;
	margin-bottom: 10px;
	background-image: url(~@/assets/news.png);
	background-position: 10px 50%;
	background-size: auto 32px;
	background-repeat: no-repeat;
}

.list-item-head[data-type="1"] {
	background-color: #1277d4;
}

.list-item-head[data-type="2"] {
	background-color: #1492ff;
}

.list-item-head[data-type="3"] {
	background-color: #F28C41;
}

.list-item-head[data-type="4"] {
	background-color: #1277d4;
}

.list-item-head[data-type="5"] {
	background-color: #1277d4;
}

.list-item-name {
	font-size: 22px;
	color: #1a234c;
	line-height: 28px;
	font-weight: bold;
	margin-bottom: 10px;
}

.list-item-date {
	color: #1277d4;
	font-size: 16px;
	margin-bottom: 10px;
}

.list-item-text {
	color: #343434;
	font-size: 16px;
	line-height: 24px;
}

</style>
